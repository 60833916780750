<template>
	<div>
		<slide-show :bannerList="bannerList" :bannerHeight="152"></slide-show>
		<div class="searchBar">
			<Search
				:keys="getListParams.key"
				@searchKeyFun="searchKeyFun"
			></Search>
		</div>
		<template>
			<div class="main_Div contBox">
				<chartletClassifyMenu
					:keys="getListParams.key"
					@changeMenu="changeMenu"
					@selectOrder="selectOrder"
					@selectFreeFun="selectFreeFun"
					@selectTypeFun="selectTypeFun"
					@selectAlbumFun="selectAlbumFun"
					@searchKeyFun="searchKeyFun"
					@changeData="changeData"
				></chartletClassifyMenu>
				
				<div class="ListBox">
					<chartlet-list
						ref="list"
						:colNum = "tabsActiveNmae"
						:chartletObj="chartletObj"
						:loading="loading"
						:listStatus="listStatus"
						@nextPage="nextPage"
					></chartlet-list>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import SlideShow from "./componts/SlideShow.vue";
import Search from "./componts/Search.vue";
import chartletClassifyMenu from "./componts/chartletClassifyMenu.vue";
import chartletList from "./componts/chartletList.vue"
// import { getBanner } from "../../Api";
import { mapGetters } from "vuex";
import { getToken } from "../../utils/auth";

export default {
	name: "CourseAlbum",
	components: {
		SlideShow,
		Search,
		chartletClassifyMenu,
		chartletList,
	},
	data() {
		return {
			bannerList: [{
				pic_str: require("../../assets/image/chartlet/chartletBg.png"),
				url: ""
			},],
			loading: false,
			listStatus: {
				isData: true,	// 判断是否有下一页
				isNull: false	// 判断是否为空
			},
			tabsActiveNmae: "单图",
			getListParams: {
				id: 6,
				child: null,
				free: 0,
				page: 0,
				px: 1,
				reCom: 0,
				videoType: 0,
				key: "",
				dataTime: null,
				comeDownBanner: "",
			},
			
		};
	},
	created() {
    if (this.$route.query.album) {
			let album = parseInt(this.$route.query.album) || 0;
			if(album == 1) {
				this.tabsActiveNmae = "贴图专辑"
			} else {
				this.tabsActiveNmae = "单张"
			}
    }
		this.initData();
	},
	methods: {
		initData() {
			// const params = {
			//   position: 1035,
			//   cate: 1050,
			// };
			// getBanner(params).then((res) => {
			//   this.bannerList = res.data;
			// });
		},
		//修改请求数据
		changeData(data) {
			this.getListParams.page = 1;
			this.getListParams = data;
		},
		//选择分类
		changeMenu(data) {
      this.$refs.list.clear();
			this.getListParams.page = 1;
			this.getListParams.id = data;
		},
		//  选择排序
		selectOrder(data) {
      this.$refs.list.clear();
			this.getListParams.page = 1;
			this.getListParams.order = data;
		},
		//  选择筛选
		selectFreeFun(is_free, is_vip) {
			this.$refs.list.clear();
			this.getListParams.page = 1;
			this.getListParams.is_free = is_free;
			this.getListParams.is_vip = is_vip;
		},
		// 选择呈现形式
		selectAlbumFun(data) {
			this.$refs.list.clear();
			this.getListParams.page = 1;
			this.getListParams.is_album = data;
			if(data == 1) {
				this.tabsActiveNmae = "贴图专辑"
			} else {
				this.tabsActiveNmae = "单张"
			}
		},
		// 选择类型
		selectTypeFun(is_brand, type) {
			this.$refs.list.clear();
			this.getListParams.page = 1;
			this.getListParams.is_brand = is_brand;
			this.getListParams.type = type;
		},
		// 下一页
		nextPage() {
			this.getListParams.page += 1;
		},
		//  搜索
		searchKeyFun(keys) {
			this.$refs.list.clear();
			if ( this.getListParams.key == keys && this.getListParams.page == 1) {
				console.log(getToken());
				this.getList()
			} else {
				this.getListParams.page = 1;
				this.getListParams.key = keys;
			}
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.listStatus.isNull = false;
			this.listStatus.isData = true;
			if ( this.getListParams.page == 1 ) {
				this.$refs.list&&this.$refs.list.clear();
			}
			this.$store
				.dispatch("app/getChartletList", this.getListParams)
				.then((res) => {
					this.loading = false;
					const { data } = res;
					if (data.total == 0) {
						this.$refs.list.clear();
						this.listStatus.isNull = true;
					} else if (data.current_page == data.last_page) {
						this.listStatus.isData = false;
					}
				});
		},
	},
	computed: {
		...mapGetters(["chartletObj"]),
	},

	watch: {
		getListParams: {
			handler: function () {
				this.getList()
			},
			deep: true,
		},
	},
};
</script>
<style scoped>
::v-deep .is-animated {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.pageBox {
	width: 100%;
	text-align: center;
}

.searchBar {
	position: absolute;
	width: 100%;
	min-width: 1400px;
	top: calc(52px + 0.68rem);
	z-index: 8;
}

.contBox {
	position: relative;
	/* top: -0.66rem; */
	z-index: 8;
}
</style>
