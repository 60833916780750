<!--分类筛选组件-->
<template>
  <div>
    <div class="classifyCont">
      <div class="classifyBox">
        <div class="classifyItem df_Yc">
          <div class="classifyItemName mr40">一级分类</div>
          <div class="classifyChild">
            <div
              class="classifyChildItem"
              :class="{ active: activeIndex == -1 }"
              @click="initData(), all(1)"
            >
              全部
            </div>
            <div
              class="classifyChildItem"
              :class="{ active: activeIndex == item.parameter }"
              v-for="(item, index) in menuList"
              :key="index"
              @click="getChildMenu(item.parameter, item.parameter)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="classifyItem df_Yc" v-show="menuChildList.length > 0">
          <div class="classifyItemName mr40">二级分类</div>
          <div class="classifyChild">
            <div
              class="classifyChildItem"
              :class="{ active: activeChildIndex == -1 }"
              @click="initData(true), all(2)"
            >
              全部
            </div>
            <div
              class="classifyChildItem"
              :class="{ active: activeChildIndex == item.parameter }"
              v-for="(item, index) in menuChildList"
              :key="index"
              @click="selectMenu(item.parameter)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ContBox">
			<div style="height: 0.36rem;"></div>
      <div class="content" :class="{ isFixe: !tagBarFixed }">
        <div class="dfc fl mr50">
          <div class="df_Yc tagName">
            <img class="tagIcon" src="@/assets/image/icon/px-icon.png" alt="" />
            <div class="tagTile">排序</div>
          </div>
          <div class="df_Yc">
            <div
              class="tag"
              v-for="(item, index) in orderOption"
              :key="index"
              :class="{ active: order == item.value }"
              @click="selectOrder(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="dfc fl mr50">
          <div class="df_Yc tagName">
            <img
              class="tagIcon"
              src="@/assets/image/icon/screen-icon.png"
              alt=""
            />
            <div class="tagTile">筛选</div>
          </div>
          <div class="df_Yc">
            <div
              class="tag"
              v-for="(item, index) in freeOption"
              :key="index"
              :class="{ active: free == item.value }"
              @click="selectFree(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="dfc fl mr50">
          <div class="df_Yc tagName">
            <img
              class="tagIcon"
              src="@/assets/image/icon/type-icon.png"
              alt=""
            />
            <div class="tagTile">类型</div>
          </div>
          <div class="df_Yc">
            <div
              class="tag"
              v-for="(item, index) in ReComOption"
              :key="index"
              :class="{ active: reCom == item.value }"
              @click="selectReCom(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
				<div class="dfc fl mr50">
					<div class="df_Yc tagName">
						<img
							class="tagIcon"
							src="@/assets/image/icon/classify-icon.png"
							alt=""
						/>
						<div class="tagTile">呈现形式</div>
					</div>
					<div class="df_Yc">
						<div
							class="tag"
							v-for="(item, index) in albumOption"
							:key="index"
							:class="{ active: album == item.value }"
							@click="selectAlbum(item.value)"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
      </div>
      <div class="content" :class="{ isFixe1: tagBarFixed }">
        <div class="fixedContent">
          <div class="fl mr40">
            <el-select
              v-model="order"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectOrder"
            >
              <el-option
                v-for="item in orderOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df_Yc fl mr40">
            <p class="f14 f0404 mr10">筛选</p>
            <el-select
              v-model="free"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectFree"
            >
              <el-option
                v-for="item in freeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df_Yc fl mr40">
            <p class="f14 f0404 mr10">类型</p>
            <el-select
              v-model="reCom"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectReCom"
            >
              <el-option
                v-for="item in ReComOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
					<div class="df_Yc fl mr40">
						<p class="f14 f0404 mr10">呈现形式</p>
						<el-select
							v-model="album"
							placeholder="请选择"
							style="width: 1.25rem"
							@change="selectAlbum"
						>
							<el-option
								v-for="item in albumOption"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</div>
          <div class="searchF fr">
            <el-input
              placeholder="请输入关键词"
              v-model="keysCont"
              @keyup.enter.native="searchKey"
              clearable
              @clear="searchKey"
            ></el-input>
            <i class="iconfont f20 c_99 c_p" @click="searchKey">&#xe67d;</i>
          </div>
          <div class="df_Yc fr mr40">
            <p class="f14 f0404 mr10">分类</p>
            <el-cascader
              ref="refHandle"
              :props="props"
              :show-all-levels="false"
              v-model="cloudList"
              @change="handleChange"
            ></el-cascader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPictureCateByFid } from "../../../Api.js";
export default {
  name: "chartletClassifyMenu",
  props: ["keys"],
  data() {
    return {
      menuList: [],
      menuChildList: [],
      activeIndex: -1,
      activeChildIndex: -1,
      cloud: null,
      cloudList: [],
      order: 0,
      free: 0,
      reCom: 0,
			album: 0,
      keysCont: "",
      tagBarFixed: false,
      //排序选项
      orderOption: [
        {
          value: 0,
          label: "综合",
        },
        {
          value: 1,
          label: "最新",
        },
        {
          value: 2,
          label: "热门",
        },
      ],
      //  筛选选项
      freeOption: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "免费",
        },
        {
          value: 2,
          label: "付费",
        },
        {
          value: 3,
          label: "VIP",
        },
      ],
      //  推荐选项
      ReComOption: [
				{
					value: 0,
					label: "全部",
				},
				{
					value: 1,
					label: "品牌",
				},
				{
					value: 2,
					label: "无缝",
				},
      ],
			albumOption: [
				{
					value: 0,
					label: "单图",
				},
				{
					value: 1,
					label: "专辑",
				},
      ],
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
      getListParams: {
				id: "",	// 分类id
				key: "",
				page: 1,
				
				order: "",	// 排序方式 1-最新 2-最热
				
				is_free: "",	// 是否付费 0-否 1-是
				is_vip: "",	// 是否vip 0-否 1-是
				
				is_brand: "",	// 是否品牌 0-否 1-是
				type: "",	// 是否无缝 0-否 1-是
				
				is_album: 0,	// 是否专辑 0-否 1-是
      },
    };
  },
  created() {
    this.keysCont = this.keys;
    if (this.$route.query.search) {
      this.getListParams.key = this.$route.query.search;
    }
    if (this.$route.query.order) {
      this.order = parseInt(this.$route.query.order) || 0;
      this.getListParams.order = this.order || "";
    }
    if (this.$route.query.free) {
      this.free = parseInt(this.$route.query.free) || 0;
			this.getListParams.is_free = this.free == 1 ? 0 : "";
			this.getListParams.is_free = this.free == 2 ? 1 : "";
			this.getListParams.is_vip = this.free == 3 ? 1 : "";
    }
    if (this.$route.query.reCom) {
      this.reCom = parseInt(this.$route.query.reCom) || 0;
			this.getListParams.is_brand = this.reCom == 1 ? 1 : "";
			this.getListParams.type = this.reCom == 2 ? 1 : "";
    }
    if (this.$route.query.album) {
      this.album = parseInt(this.$route.query.album) || 0;
      this.getListParams.is_album = this.album;
    }
    //获取路由参数
    if (this.$route.query.child1 && this.$route.query.child2) {
      this.activeIndex = this.$route.query.child1;
      this.activeChildIndex = this.$route.query.child2;
      this.cloudList = [
        parseInt(this.$route.query.child1),
        parseInt(this.$route.query.child2),
      ];
      this.initData(false);
      this.getListParams.id = this.activeChildIndex;
      this.$emit("changeData", this.getListParams);
      return;
    }
    if (this.$route.query.child1) {
      this.activeIndex = this.$route.query.child1;
      this.cloudList = [parseInt(this.activeIndex)];
      this.initData(false);
      this.getListParams.id = this.activeIndex;
			this.$emit("changeData", this.getListParams);
    } else {
      this.initData(false);
			this.$emit("changeData", this.getListParams);
    }
  },
  methods: {
    all(type) {
      if (type == 1) {
        this.activeIndex = -1;
        //将参数设置到地址栏
        const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
        delete query.child1; //改变参数
        delete query.child2;
        this.$router.push({ path: this.$route.path, query });
        this.menuChildList = []
      } else {
        this.activeChildIndex = -1;
        //将参数设置到地址栏
        const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
        delete query.child2; //改变参数
        this.$router.push({ path: this.$route.path, query });
        this.cloud = this.activeIndex;
      }
    },
    initData(isChild) {
      if (!isChild) {
        if (this.$refs.refHandle) this.resetSelectMenu();
        this.getPictureCateByFid(2205).then((res) => {
          this.menuList = res.data.data;
        });
      } else {
        if (this.activeIndex != -1) this.getChildMenu(this.activeIndex);
      }
    },
    //获取菜单
    getPictureCateByFid(id) {
      return new Promise((resolve) => {
        getPictureCateByFid(id).then((res) => {
          resolve(res);
        });
      });
    },
    //动态加载菜单
    lazyLoad(node, resolve) {
      const { level } = node;
      const id = level == 0 ? 2205 : node.value;
      this.getPictureCateByFid(id).then((res) => {
        if (level == 1) {
          this.menuChildList = res.data.data;
        }
        const nodes = res.data.data.map((item) => ({
          value: item.parameter,
          label: item.name,
          leaf: node.level >= 1,
        }));
        resolve(nodes);
      });
    },
    // 获取二级菜单
    getChildMenu(id) {
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.child1 = id; //改变参数
      if (this.activeIndex != id) delete query.child2;
      this.activeChildIndex = -1;
      this.cloudList = [parseInt(this.activeIndex)];
      this.$router.push({ path: this.$route.path, query });
      this.getPictureCateByFid(id).then((res) => {
        this.resetSelectMenu();
        this.activeIndex = id;
        this.menuChildList = res.data.data;
        this.cloud = id;
      });
    },
    //重置菜单悬着器
    resetSelectMenu() {
      this.$refs.refHandle.$refs.panel.checkedValue = [];
      this.$refs.refHandle.$refs.panel.activePath = [];
      this.$refs.refHandle.$refs.panel.syncActivePath();
    },
    // 点击二级菜单
    selectMenu(id) {
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.child2 = id; //改变参数
      this.$router.push({ path: this.$route.path, query });
      let cs = this.$refs["refHandle"];
      cs.panel.activePath = [];
      cs.panel.loadCount = 0;
      cs.panel.lazyLoad();
      this.activeChildIndex = id;
      this.cloud = id;
      this.cloudList = [parseInt(this.activeIndex), parseInt(id)];
    },
    handleChange() {
      if (this.cloudList.length == 0) {
        if (this.$route.query.child1) {
          this.cloudList = [parseInt(this.activeIndex)];
        }
        if (this.$route.query.child1 && this.$route.query.child2) {
          this.cloudList = [
            parseInt(this.activeIndex),
            parseInt(this.activeChildIndex),
          ];
        }
      }
      let id = null;
      if (this.cloudList.length == 1) {
        // 选定一级菜单
        id = this.cloudList[0];
        this.resetSelectMenu();
        this.menuList.filter((obj) => {
          if (obj.parameter === id) {
            this.getPictureCateByFid(id).then((res) => {
              this.activeChildIndex = -1;
              this.activeIndex = id;
              this.menuChildList = res.data.data;
              //将参数设置到地址栏
              const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
              query.child1 = this.activeIndex; //改变参数
              this.$router.push({ path: this.$route.path, query });
            });
          }
        });
      } else {
        // 选定二级菜单
        id = this.cloudList[1];
        let parentId = this.cloudList[0];
        this.menuList.filter((obj) => {
          if (obj.parameter === parentId) {
            this.activeIndex = parentId;
            const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
            query.child1 = this.activeIndex; //改变参数
            this.$router.push({ path: this.$route.path, query });
          }
        });
        this.menuChildList.filter((obj) => {
          if (obj.parameter === id) {
            this.activeChildIndex = id;
            //将参数设置到地址栏
            const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
            query.child2 = this.activeChildIndex; //改变参数
            this.$router.push({ path: this.$route.path, query });
          }
        });
      }
      this.cloud = id;
      // 监听值发生变化就关闭它
      if (this.$refs.refHandle)
        return (this.$refs.refHandle.dropDownVisible = false);
    },
    //选择排序
    selectOrder(order) {
      this.order = order;
      this.$emit("selectOrder", order);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.order = order; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择筛选
    selectFree(free) {
      this.free = free;
			if (free == 1) {
				this.$emit("selectFreeFun", 0, "");
			} else if (free == 2) {
				this.$emit("selectFreeFun", 1, "");
			} else if (free == 3) {
				this.$emit("selectFreeFun", "", 1);
			} else {
				this.$emit("selectFreeFun", "", "");
			}
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.free = free; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择推荐
    selectReCom(reCom) {
      this.reCom = reCom;
			if (reCom == 1) {
				this.$emit("selectTypeFun", 1, "");
			} else if (reCom == 2) {
				this.$emit("selectTypeFun", "", 1);
			} else {
				this.$emit("selectTypeFun", "", "");
			}
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.reCom = reCom; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择呈现形式
    selectAlbum(album) {
      this.album = album;
      this.$emit("selectAlbumFun", album);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.album = album; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //搜索
    searchKey() {
      this.$emit("searchKeyFun", this.keysCont);
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const offsetTop = document.querySelector(".ContBox").offsetTop * 3;
      if (scrollTop > offsetTop) {
        this.tagBarFixed = true;
      } else {
        this.tagBarFixed = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    cloud(val) {
      this.$emit("changeMenu", val);
    },
    keys(val) {
      this.keysCont = val;
    },
    // $route(){
    // 		this.$router.go(0);
    // 	}
  },
};
</script>

<style scoped>
.searchF >>> .el-input__inner {
  border: 0;
}
::v-deep .el-cascader {
  line-height: 0.28rem;
}
.searchF {
  width: 2.2rem;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-right: 0.1rem;
}
.classifyChildItem.active {
  background: #f46600;
  border-radius: 0.2rem;
  color: #ffffff;
}

.classifyChildItem:hover {
  background: #f46600;
  border-radius: 0.2rem;
  color: #ffffff;
  transform: translateY(-2px);
}

.classifyChildItem {
  cursor: pointer;
  margin-right: 0.15rem;
  padding: 0.05rem 0.15rem;
  transition: 0.2s;
}

.classifyChild {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  font-size: 0.14rem;
  color: #333333;
}

.classifyItem {
  margin-top: 0.3rem;
}

.classifyItemName {
  color: #040404;
  font-size: 0.14rem;
  font-weight: bold;
}

.classifyCont {
  width: 100%;
  background: #ffffff;
  padding: 0.06rem 1.2rem 0.36rem;
	box-shadow: 0px 4px 20px 0px rgba(194, 194, 194, 0.2);
}

.classifyBox {
  display: flex;
  flex-direction: column;
  border-radius: 0.08rem;
}
.fixedContent {
  width: 100%;
  min-width: 1400px;
  padding: 0 1.2rem;
}
.content {
  /* visibility: hidden; */
  opacity: 0;
  transition: opacity 0.5s;
  padding: 0 1.2rem;
}
.content.isFixe {
  visibility: visible;
  opacity: 1;
  /* margin-top: 0.4rem; */
}
.content.isFixe1 {
  /* visibility: visible; */
  opacity: 1;
  padding: 0.2rem 0;
  background: #ffffff;
  position: fixed;
  top: 0.68rem;
  width: 100%;
  z-index: 8;
}
.select {
  width: 1.45rem;
}

::v-deep .el-picker-panel {
  margin: 5px 1.2rem 5px 0 !important;
}

::v-deep .el-input {
  width: 100% !important;
  font-size: 0.14rem !important;
}

::v-deep .el-input__inner {
  height: 0.28rem;
  line-height: 0.28rem;
}

::v-deep .el-input__icon {
  line-height: 0.28rem;
}

.content:after {
  content: "";
  visibility: hidden;
  display: block;
  width: 0;
  height: 0;
  clear: both;
}

.select .el-input__inner.el-input__inner {
  background-color: #f7f6fb;
}

.tagName {
  margin-right: 0.2rem;
  line-height: 0.28rem;
}

.tagIcon {
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.08rem;
}

.tagTile {
  color: #040404;
  font-size: 0.14rem;
}

.ContBox {
  width: 100%;
  min-width: 12rem;
	height: 1rem;
}

.tag {
  padding: 0.04rem 0.1rem;
  background: #fff;
  border-radius: 0.04rem;
  margin-right: 0.15rem;
  font-size: 0.14rem;
  color: #333333;
  cursor: pointer;
  transition: 0.15s;
}

.tag:hover {
  background: #f46600;
  color: #ffffff;
  transform: translateY(-2px);
}

.tag.active {
  background: #f46600;
  color: #ffffff;
}
</style>
