<!--各主页搜索组件-->
<template>
	<div class="w100">
		<div class="dfc searchBox m_c">
			<el-input
				type="text"
				v-model="searchKey"
				placeholder="请输入关键词，查找贴图"
				@keyup.enter.native="searchKeyF"
				clearable
				@clear="searchKeyF"
			/>
			<div
				class="dfc c_p buttonBg searchButton"
				@click="searchKeyF"
			>
				<img src="@/assets/image/icon/search-icon.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: "Search",
  props: ["keys"],
  data() {
    return {
      searchKey: "",
			fid: "",
    };
  },
  created() {
    if (this.$route.query.search) {
      this.searchKey = this.$route.query.search;
    }
  },
  methods: {
    searchKeyF() {
      this.$emit("searchKeyFun", this.searchKey);
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.search = this.searchKey; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
  },
  watch: {
    keys(val) {
      this.searchKey = val;
    },
  },
};
</script>

<style scoped>
.searchBox >>> .el-input__inner {
  border: 0;
}
.searchBox {
  width: 6.8rem;
  height: 0.48rem;
  border-radius: 0.08rem;
  background: #ffffff;
  overflow: hidden;
}

.searchBox input {
  width: 5.66rem;
  height: 0.48rem;
  padding-left: 0.2rem;
}

.searchBox input::-webkit-input-placeholder {
  color: #bfbfbf;
  font-size: 0.14rem;
}

.searchButton {
  width: 1.14rem;
  height: 0.48rem;
}

.buttonBg {
  background: #f46600;
}

.searchBox p {
  width: 0px;
  height: 0.3rem;
  border: 1px solid #040404;
  opacity: 1;
}

.searchButton img {
  width: 0.28rem;
  height: 0.28rem;
}
</style>